.lole-ui-main-header-wrapper{
    margin-top: 20px;
    color: rgb(249,204,226);

}

.lole-ui-main-title-span{
    font-size: 60px;
    color: #ffffff;

}

.lole-ui-main-title{
    font-size: 110px;
    font-weight: normal;
}

.lole-ui-main-text{
    font-weight: lighter;
}

.lole-ui-main-card-title{
    font-size:x-large;
    margin:30px;
}

.lole-ui-main-card-btn-link{
    margin-left: 20px;
}

.lole-ui-main-card-span{
    width: 85%;
    margin-left: 35px;
    white-space: pre-wrap;
    color: gray;
    font-size: medium;
}

.lole-ui-main-card-small{
    height: 26vh;
   /* border: 1px white solid;*/
    width: 90%;
    border-radius: 15px;
}

.lole-ui-main-card-small-title{
    padding-top: 25px;
    margin: 40px;
    font-size: 25px;
    font-weight: bold;
}

.lole-ui-main-card-small-space{
    margin-left: 30px;
    margin-top: 20px;
}

.lole-ui-main-h2{
    margin-top: 20px;
    margin-left: 40px;
}

.lole-ui-main-more-card{
    height: 35vh;
    margin: 40px;
    border-radius: 12px;
}

.lole-ui-main-more-card-img{
    height: 22vh;
    width: 100%;
}

.lole-ui-main-more-card-title{
    margin-top: 5px;
    font-size: 18px;
    font-weight: 550;
}

.lole-ui-main-more-card-content{
    margin-top: 5px;
    margin-left: 10px;
    color: gray;
}

.lole-ui-main-card-four-img{
    margin: auto;
    text-align: center;
}

.lole-ui-main-card-four-img-name{
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
}

.lole-ui-card-bg{
    width: 100%;
    height: 100%;
    z-index: 0;
}

.lole-ui-one-card{
    background-image: url("../assest/img/card01.png");
}

.lole-ui-two-card{
    background-image: url("../assest/img/card02.png");
}

.lole-ui-small-one-card{
    width: 100%;
    background-image: url("../assest/img/sm01.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.lole-ui-small-two-card{
    width: 100%;
    background-image: url("../assest/img/sm02.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.lole-ui-small-three-card{
    width: 100%;
    background-image: url("../assest/img/sm03.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.lole-ui-small-four-card{
    width: 100%;
    background-image: url("../assest/img/sm04.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}


.lole-ui-small-five-card{
    width: 100%;
    background-image: url("../assest/img/sm05.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.lole-ui-small-six-card{
    width: 100%;
    background-image: url("../assest/img/sm06.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size:100% 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.lole-ui-small-card{
    border-left: 1px rgba(249,204,226, 0.5) solid;
    border-right: 1px rgba(249,204,226, 0.5) solid;
    border-bottom: 1px rgba(249,204,226, 0.5) solid;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}

.lole-ui-main-card{
    background-image: url("https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*i1EySrFVZvAAAAAAAAAAAABkARQnAQ");
    border-radius: 12px;
}

.lole-ui-home-font{
    color: rgba(246,202,226,1);
    padding-top: 100px;
    margin-left: 65%;
    font-size: x-large;
}

.lole-ui-bg{
    background-image: url("../assest/bbg.png");
    background-repeat: no-repeat;
    background-size:65% 100%;
}


.lole-ui-read-btn{
    position: fixed;
    right: 10vh;
}
/*取消antd的默认样式*/
.ant-drawer-body{
    padding: 0!important;
}

.lole-ui-read-drawer{
    margin-top: 20px;
}

.lole-ui-main-svg{
    cursor: pointer;
}
